import './style.css';
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <header>
      <a href="https://taggartshirefarm.com"><img className="logo" src="images/taggartshire logo website.png" alt="taggartshire farm logo - three sheep heads, one with dark markings" /></a>
      <nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/lambs">Lambs</NavLink>
        <NavLink to="/fiber">Fiber</NavLink> 
        <NavLink to="/produce">Produce</NavLink>
      </nav>
    </header>
  );
}

export default Header;
