import './style.css';

const Footer = () => {
    return (
        <footer>
            <p>© 2022</p>
            {/*<p className="small">Designed & Developed by <a href="https://curiositybard.com">Briar Taggart</a></p>*/}
        </footer>
    );
}

export default Footer;