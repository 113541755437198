import React from 'react';
import '../style.css';

const Contact = (props) => {
    return (
        <div class="contact-info">
            <h3>Contact Us</h3>
            <p>Contact us at <a href="mailto:info@taggartshirefarm.com" target="_blank" rel="noreferrer">info@taggartshirefarm.com</a>.</p>
        </div>
    );
}

export default Contact;