import React from 'react';
import '../style.css';
import Soon from '../segments/soon';

const Produce = (props) => {
    return (
        <section id="produce-section">
            <Soon section="produce" />
        </section>
    );
};

export default Produce;