import React from 'react';
import '../style.css';
import Contact from './contact';

function toFirstUpper(word) {
    let upperCase = [];
    for (let i = 0; i < word.length; i++) {
        if (i === 0) {
            upperCase.push(word[i].toUpperCase());
        } else {
            upperCase.push(word[i]);
        }
    }
    upperCase = upperCase.join("");
    console.log(upperCase);
    return upperCase;
}

const Soon = (props) => {
    let sectionCaps = toFirstUpper(props.section);
    return (
        <div id="coming-soon" className="blurb">
            <h2>{sectionCaps} is Coming Soon</h2>
            <p>Please check back later to learn more about {props.section} from Taggartshire Farm. In the meantime, you can contact us.</p>
            <Contact />
        </div>
    );
};

export default Soon;