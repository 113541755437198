import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import './style.css';
import Header from './Header.js';
import Home from './routes/home.js';
import Lambs from './routes/lambs.js';
import Fiber from './routes/fiber';
import Produce from './routes/produce';
import Footer from './footer.js';

const meta = {
  title: "Taggartshire Farm",
  description: "Registered shetland sheep, soft fleeces, and produce in season from Chimacum, WA.",
  canonical: "https://taggartshirefarm.com",
  image: "images/taggartshire logo website.png",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "farm,chimacum,sheep,wool,fleeces,lambs,produce,homegrown"
    }
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <DocumentMeta {...meta} />
    <Header />
    <Routes>
      <Route path={process.env.PUBLIC_URL + '/'} element={<Home />} />
      <Route path={process.env.PUBLIC_URL + '/lambs'} element={<Lambs />} />
      <Route path={process.env.PUBLIC_URL + '/fiber'} element={<Fiber />} />
      <Route path={process.env.PUBLIC_URL + '/produce'} element={<Produce />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);