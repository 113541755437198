import React from 'react';
import '../style.css';
import Contact from '../segments/contact.js';

const About = (props) => {
    return (
        <div id="about-section">
            <h2>About Taggartshire Farm</h2>
            <img id='main-img' className='large-img' src='images/farm.jpg' alt="a field behind a fence, with forested hills and mountains in the distance" />
            <p>Welcome to Taggartshire farm! Here you can find registered shetland sheep, soft fleeces, and produce in season.</p>
        </div>
    );
}

const Home = (props) => {
    return (
        <section id="home-page" class="container">
            <About />
            <Contact />
        </section>
    );
};

export default Home;