import React from 'react';
import '../style.css';

let lambPictures = [
    {
        name: "Captain Jack Sparrow", 
        src: "Captain Jack Sparrow.JPG", 
        alt: "A spotted black and white lamb"
    },
    {
        name: "Eomer", 
        src: "Eomer.JPG", 
        alt: "A black lamb"
    },
    {
        name: "Eowyn", 
        src: "Eowyn.JPG", 
        alt: "A white lamb with black markings and feet"
    },
    {
        name: "Frodo", 
        src: "Frodo.JPG", 
        alt: "A white lamb with dark markings"
    },
    {
        name: "Merry", 
        src: "Merry.JPG", 
        alt: "A white lamb with pale eyes"
    },
    {
        name: "Pippin", 
        src: "Pippin.JPG", 
        alt: "A white lamb with brown markings"
    },
    {
        name: "Shadow", 
        src: "Shadow.JPG", 
        alt: "A black lamb"
    }
];

const Lambs = () => {
    return (
        <section id="lambs-section">
            <Blurb />
            <LambPics images={lambPictures} />
        </section>
    );
};

const Blurb = () => {
    return (
        <>
            <h2>Lambs for Sale</h2>
            <img src="images/Captain Jack Sparrow, Merry, Pippin.JPG" alt="one spotted black and white sheep, one white sheep, and one white sheep with black spots on its legs, standing in a line" class="large-img" id="lambs-pic" />
            <p> We still have one ewe lamb and several wethers from last spring. Contact us for photos and prices.  More lambs due in April and May.</p>
        </>
        
    );
}

const LambPics = (props) => {
    const [currentImg, setCurrentImg] = React.useState(0);

    return (
        <>
            <figure id="one-lamb">
                <img src={'images/' + props.images[currentImg].src} alt={props.images[currentImg]} id="main-lamb" />
                <figcaption>{props.images[currentImg].name}</figcaption>
            </figure>
            <p className="center-text">Click on a lamb below to see a larger image.</p>
            <div id="lamb-pictures" className='grid'>
                {
                    props.images.map((lamb, i) => (
                        <figure id={'lamb' + i} key={'fig' + i} className="thumbnail" onClick={() => {setCurrentImg(i)}}>
                            <img src={'images/' + lamb.src} alt={lamb.alt} />
                            <figcaption>{lamb.name}</figcaption>
                        </figure>
                    ))
                }
            </div>
        </>
        
    )
}

export default Lambs;