import React from 'react';
import '../style.css';

let rovings = [
    {
        caption: "White",
        src: "rovingbraid.JPG",
        alt: "a braid of white roving"
    },
    {
        caption: "White",
        src: "rovingspiral.JPG", 
        alt: "a spiral braid of white roving"
    },
    {
        caption: "Grey",
        src: "greywool.JPG",
        alt: "a piece of grey wool"
    },
    {
        caption: "Moorit",
        src: "ltgreyroving.JPG",
        alt: "a braid of light grey roving"
    },
    {
        caption: "Grey",
        src: "greyroving.JPG",
        alt: "a spiral braid of grey roving"
    },
    {
        caption: "Grey",
        src: "greyroving2.JPG",
        alt: "a braid of grey roving"
    },
    {
        caption: "Dark Grey",
        src: "blackroving.JPG", 
        alt: "a braid of black roving"
    }
];

const Main = () => {
    return (
        <>
            <h2>Shetland Wool</h2>
                <p>Shetland roving spins up light and fluffy. Two ply yarn pictured here spun at DK weight, 250 yds/5 oz.</p>
                <div id="yarns">
                    <img className="yarn-img" src="images/yarn.JPG" alt="a bundle of white yarn" />
                    <img className="yarn-img" src="images/yarnclose.JPG" alt="a bundle of white yarn, close up" /> 
                </div>
            <h2>Roving for Sale</h2>
            <p>
                Professionally processed roving and bumbs $4/oz. Available in natural white, light moorit, dark moorit, gray, and dark gray.
            </p>
            <p>
                Professionally processed braids for easy dyeing $4.50/oz. Available in natural white and dark gray.
            </p>
            <p>
                Contact me with your weights required and color choices.
            </p>
            <Rovings images={rovings} />
            <h2>Raw Wool Pre-Orders</h2>
            <p>
                Currently taking pre-orders now for raw fleeces available mid-March. $40 each. Tell me what colors/textures you're looking for and I will get your pictures of sheep that fit the bill.
            </p>
        </>
    )
}

const Rovings = (props) => {
    return (
        <div id="fiber-images">
            {
                props.images.map((fiber, i) => (
                    <figure id={'fiber' + i} key={'fig' + i} className="fiber-fig">
                        <img className="roving-pic" src={'images/' + fiber.src} alt={fiber.alt} />
                        <figcaption>{fiber.caption}</figcaption>
                    </figure>
                ))
            }
        </div>
        
    );
}

const Fiber = (props) => {
    return (
        <section id="fiber-section">
            <Main />
        </section>
    );
};

export default Fiber;